import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import {TextField, Button, Avatar, Tooltip, Box, List, ListItem, ListItemText, ListItemIcon, InputAdornment, IconButton} from '@mui/material/';

import {Visibility, VisibilityOff} from '@mui/icons-material/';


class Message extends React.Component {
  /* Props:
   * * text: String
   * * username: String 
   */

  stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  stringAvatar(name: string) {
    var chars = ""
    var split = name.split(' ');
    if (split.length === 0)
    {
      chars = "!";
    }
    else if (split.length === 1)
    {
      chars = name.split(' ')[0][0];
    }
    else 
    {
      chars = "" + name.split(' ')[0][0] + name.split(' ')[1][0];
    }
    return {
      sx: {
        bgcolor: this.stringToColor(name),
      },
      children: chars,
    };
  }

  render() {
    var color = "#D0D0D0"
    if (this.props.index % 2 == 0)
    {
      color = "#E0E0E0"
    }
    return (
      <ListItem 
        disablePadding 
        sx={{
          bgcolor: color,
          pt: 0.5,
          pb: 0.5,
          pl: 2,
          pr: 2,
          borderRadius: 2,
          width: '100%',
                    
        }}
      >
        <ListItemIcon>
          <Tooltip title={this.props.username}>
            <Avatar {...this.stringAvatar(this.props.username)} alt={this.props.username} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={this.props.message} />
      </ListItem>
  );
  }
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      showPassword: false,
      userErrorMessage: null,
      passErrorMessage: null,
    }
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  /* Props
   * * onLoginSuccess: function(String)
   */
  render() {
    return (
      <div>
        <h1>Login</h1>
        <div>
          <TextField 
            id="username-field" 
            label={this.state.userErrorMessage === null ? "Username" : this.state.userErrorMessage }
            variant="outlined" 
            error={this.state.userErrorMessage !== null}
            onChange={(event) => {
              this.setState({
                username: event.target.value,
              });
            }}
          />
          <TextField 
            id="password-field" 
            type={this.state.showPassword ? "text" : "password"}
            label={this.state.passErrorMessage === null ? "Password"  : this.state.passErrorMessage }
            variant="outlined" 
            error={this.state.passErrorMessage !== null}
            onChange={(event) => {
              this.setState({
                password: event.target.value,
              });
            }}
            InputProps={{
              endAdornment: 
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      var show = this.state.showPassword;
                      this.setState({
                        showPassword: !show,
                      });
                    }}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            }}
          />
           
        </div>
        <div>
          <Button 
            sx={{ m: 1, p: 2, pl: 15, pr: 15, }} 
            variant="contained" 
            onClick={() => {
              if (this.state.username === null || this.state.username === "")
              {
                this.setState({
                  userErrorMessage: "Username must not be empty."
                });
              }
              else if (this.state.password !== "truth!")
              {
                this.setState({
                  userErrorMessage: null,
                  passErrorMessage: "Password is not correct!"
                });
              }
              else
              {
                this.setState({
                  userErrorMessage: null,
                  passErrorMessage: null,
                });
                this.props.onLoginSuccess(this.state.username)
              }
            }}>
            Login
          </Button>
        </div>

      </div>
    );
  }
}

class MessagePostForm extends React.Component {
  /* Props:
   * onMessagePosted: function(String)
   */
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      error: null,
    };
  }
  
  render() {
    return (
      <div>
        <h2>Post a truthier...</h2>
        <TextField 
          sx={{
            m: 1, 
            width: '75ch'
          }}
          id="outlined-basic" 
          label={this.state.error === null ? "Message" : this.state.error}
          variant="outlined" 
          onChange={(event) => {
            this.setState({
              message: event.target.value,
            });
          }}
        />
        <br/>
        <Button 
          sx={{ m: 1, p: 2, pl: 15, pr: 15, }}
          variant="contained" 
          onClick={() => {
            if (this.state.message === null || this.state.username === "")
            {
              this.setState({
                error: "Message must not be empty."
              });
            }
            else
            {
              var message = this.state.message;
              this.setState({
                message: null,
                error: null,
              });
              this.props.onMessagePosted(message)
            }
          }}>
          Post
        </Button>
      </div>
    );
  }
}

class FakeTwitter extends React.Component {
  constructor(props) {
    super(props);
    var messages = Array(0);
    messages.push("Welcome to Profit!");
    messages.push("Hi Paddy.");
    // TODO: Set username to null
    this.state = {
      username: null, 
      messages: messages,
    };
  }

  render() {
    if (this.state.username === null)
    {
      return (
        <Login
          onLoginSuccess = {(username) => {
            this.setState({
              username: username,
            });
          }}
        />
      )
    }
    else
    {
      let messages = Array(0);
      for (let i = this.state.messages.length-1; i >= 0; i--)
      {
        messages.push(<Message key={this.state.messages[i]} index={i} message={this.state.messages[i]} username={this.state.username} />)
      }
      return (
        <div>
          <MessagePostForm onMessagePosted={(message) => {
            messages = this.state.messages.concat(message);
            console.log("Message posted:"+ message)
            this.setState({
              messages: messages,
            });
          }}/>
          <div  className="messages">
            <Box sx={{boxShadow: 12,
                      borderRadius: 2,
                      width: '100%',
                      bgcolor: '#E0E0E0',
                    }}>
              <List>
                {messages}
              </List>
            </Box>
          </div>
        </div>
      );
    }
  }
}

function App() {
  return (
    <div className="App">
      <div id="page-header">
        Truthier Social!
      </div>
      <div id="page-body">
        <FakeTwitter/>
      </div>
    </div>
  )
}

export default App;
